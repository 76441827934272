<!--
* @description:
* @fileName articleEdit.vue
* @author hvv
* @date 2022/01/19 09:56:21
!-->
<template>
  <div>
    <el-card>
      <div class="demo-drawer__content">
        <el-form ref="formRef" :model="form" :rules="rules">
          <el-form-item label="广告名称">
            <el-input
              placeholder="请输入广告名称"
              v-model="form.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="广告排序">
            <el-input
              placeholder="请输入排序值，未设置默认排序在最后"
              v-model="form.sort"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="广告类型" prop="logo">
            <el-radio-group v-model="form.mediaType">
              <el-radio :label="'img'">图片</el-radio>
              <el-radio :label="'video'">视频</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="广告图片"
            prop="logo"
            v-if="form.mediaType == 'img'"
          >
            <div class="upLoadImg">
              <wmt-image :src="form.logo" @deleteImg="deleteLogo"></wmt-image>
              <div>
                <el-button @click="upLoadLogo">上传图片</el-button>
                <p>建议上传比例为1：1，335px*160px，小于5MB的图片</p>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            label="广告视频"
            prop="cover"
            v-if="form.mediaType == 'video'"
          >
            <div class="upLoadImg">
              <video
                :src="form.videoSrc"
                controls
                style="width: 200px; height: 200px"
              ></video>
              <div>
                <el-button @click="upLoadVideo">上传视频</el-button>
                <p>建议上传比例为1：1，335px*160px，小于20MB的视频</p>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            label="视频封面"
            prop="cover"
            v-if="form.mediaType == 'video'"
          >
            <div class="upLoadImg">
              <wmt-image :src="form.cover" @deleteImg="deleteCover"></wmt-image>
              <div>
                <el-button @click="upLoadCover">上传图片</el-button>
                <p>建议上传比例为5：4，小于10MB的图片</p>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="链接地址" prop="cover">
            <div class="upLoadImg">
              <!-- <wmt-image :src="form.cover" @deleteImg="deleteCover"></wmt-image> -->
              <div style="font-size: 12px; color: 999; margin-right: 20px">
                <template v-for="(sItem, sIndex) in link" :key="sIndex">
                  <div v-if="sItem.type == 'shop'">
                    店铺： {{ sItem.shopName }}
                  </div>

                  <div v-if="sItem.type == 'active'">
                    活动 {{ sItem.title }}
                  </div>
                  <div v-if="sItem.type == 'Article'">
                    内容 {{ sItem.title }}
                  </div>
                  <div v-if="sItem.type == 'page'">
                    平台页面 {{ sItem.name }}
                  </div>
                  <div v-if="sItem.type == 'exhPage'">
                    展会页面 {{ sItem.name }}
                  </div>
                  <div v-if="sItem.type == 'exhibition'">
                    展会 {{ sItem.meetingName }}
                  </div>
                  <div v-if="sItem.type == 'miniPath'">
                    页面 {{ sItem.name }}
                  </div>
                  <div v-if="sItem.type == 'link'" class="linkWidth">
                    链接 {{ sItem.url }}
                  </div>
                  <div v-if="sItem.type == 'mini'">
                    小程序名称 {{ sItem.miniData.name }}
                  </div>
                  <div v-if="sItem.type == 'wxVideo'">
                    视频号Id {{ sItem.wxVideoType.finderUserName }}
                  </div>
                  <div v-if="sItem.type == 'holdMeet'">
                    会议名称 {{ sItem.name }}
                  </div>
                </template>
              </div>
              <div>
                <el-button @click="handleClickFile">选择链接</el-button>
              </div>
            </div>
          </el-form-item>

          <el-form-item align="center">
            <!-- <el-button @click="handleClose">取消</el-button> -->
            <el-button
              size="large"
              style="width: 200px"
              type="primary"
              @click="submitInfo()"
            >
              提交
            </el-button>
            <el-button
              size="large"
              style="width: 200px"
              type="primary"
              @click="goBack()"
            >
              返回
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <vue-cropper
      ref="vabCropperRef"
      @imgInfo="logoInfo"
      :title="'广告图片'"
      :option="brandLogoOption"
    />

    <vue-cropper
      ref="coverCropperRef"
      @imgInfo="coverInfo"
      :title="'视频封面'"
      :option="brandLogoOption"
    />

    <!-- 上传视频 -->
    <upLoad-video ref="upLoadVideoRef" @addVideo="handleAddVideo" />
    <choose-link
      ref="chooseLinkRef"
      :mult-choose="multChooseFlag"
      @chooseLink="confirmChoose"
    />
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onActivated,
    onMounted,
    reactive,
    toRefs,
    defineAsyncComponent,
    shallowRef,
    onBeforeUnmount,
  } from 'vue'
  import { upLoadImg } from '@/api/active'
  import { saveAdvertisingConfig, getAdvertisingConfig } from '@/api/advert'
  import { parseTime } from '@/utils/index'
  import VabQuill from '@/extra/VabQuill'
  import VabUpload from '@/extra/VabUpload'
  import VueCropper from '@/extra/VueCropper'
  import { Picture } from '@element-plus/icons'
  import wmtImage from '@/components/wmt-image'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import '@wangeditor/editor/dist/css/style.css'
  import { useRoute } from 'vue-router'
  import upLoadVideo from '@/components/upLoadVideo'
  export default defineComponent({
    name: 'ArticleEdit',
    components: {
      VabQuill,
      VabUpload,
      VueCropper,
      wmtImage,
      Draggable: defineAsyncComponent(() => import('vuedraggable')),
      chooseLink: defineAsyncComponent(() =>
        import('@/components/chooseLink/chooseLink')
      ),
      Editor,
      Toolbar,
      upLoadVideo,
      // VueUeditorWrap,
    },
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const route = useRoute()
      // 初始化编辑器
      const editorRef = shallowRef()
      const state = reactive({
        dialogVisible: false,
        articleVisible: false,
        vabCropperRef: null,
        chooseLinkRef: null,
        coverCropperRef: null,
        brandLogoOption: {
          outputSize: 1, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
          info: true, // 裁剪框的大小信息
          canScale: true, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 335, // 默认生成截图框宽度
          autoCropHeight: 160, // 默认生成截图框高度
          fixedBox: true, // 固定截图框大小 不允许改变
          fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
          fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
          canMove: true, // 上传图片是否可以移动
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: true, // 截图框是否被限制在图片里面
          infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          full: true, // 是否输出原图比例的截图
          enlarge: '1', // 图片根据截图框输出比例倍数
          mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
        },
        formRef: null,
        vabUploadRef: null,
        upLoadVideoRef: null,
        form: {
          name: ``,
          tagIds: [],
          logo: '',
          cover: '',
          banner: [''],
          advertisingCode: '',
          advertisingRelationCode: '',
          mediaType: 'img',
          id: '',
        },
        link: [],
        rules: {
          exhibitorName: [
            { required: true, trigger: 'blur', message: '请输入展商名称' },
          ],
          brandName: [
            { required: true, trigger: 'blur', message: '请输入品牌简称' },
          ],
          businessCategory: [
            { required: true, trigger: 'blur', message: '请输入经营品类' },
          ],
        },
        status: 'new',
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          advertisingCode: '',
        },
      })

      const upBannar = async (e) => {
        if (state.form.banner.length >= 10) {
          proxy.$baseMessage(
            `当前限制选择 10 个文件，本次选择了10个文件`,
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        state.form.banner.length == 1 && state.form.banner[0] == ''
          ? (state.form.banner[0] = data)
          : state.form.banner.push(data)
        console.log(state.form.banner)
      }

      const handlePreview = (url) => {
        console.log(url)
        state.dialogImageUrl = url
        state.dialogVisible = true
      }

      const submitInfo = async () => {
        console.log(state.form)
        const data = {
          extraParams: { videoSrc: null, isVideo: false },
          img: null,
          index: state.form.sort,
          isRegister: false,
          link: state.link,
          size: '750*350',
          url: '',
        }

        if (state.form.mediaType == 'img') {
          data.img = state.form.logo
        } else {
          data.extraParams.videoSrc = state.form.videoSrc
          data.extraParams.isVideo = true
          data.img = state.form.cover
        }

        let params = {
          advertisingRelationType: 'EXHIBITION',
          advertisingCode: state.form.advertisingCode,
          advertisingRelationCode: state.form.advertisingRelationCode,
          bannerType: 'exhibitionBanner',
          name: state.form.name,
          param: JSON.stringify(data),
          id: state.form.id,
        }

        if (state.form.mediaType == 'img' && !state.form.logo) {
          proxy.$baseMessage(`广告图片未上传`, 'error', 'vab-hey-message-error')
          return false
        }
        if (state.form.mediaType == 'video' && !state.form.videoSrc) {
          proxy.$baseMessage(`广告视频未上传`, 'error', 'vab-hey-message-error')
          return false
        }
        console.log(data, params)

        const { data: adverInfo } = await saveAdvertisingConfig(params)
        console.log(adverInfo)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        proxy.$router.replace({
          path: '/advert/ground',
          // query: {
          //   meetingCode: state.form.meetingCode,
          // },
        })
        // state.form.bannerList = state.form.banner
        // let { data, msg } = await addBrand(state.form)
      }

      const upLoadLogo = () => {
        state['vabCropperRef'].dialogVisible = true
      }

      const logoInfo = (logo) => {
        console.log('logo', logo)
        state.form.logo = logo
      }
      const upLoadCover = () => {
        state['coverCropperRef'].dialogVisible = true
      }

      const coverInfo = (logo) => {
        console.log('logo', logo)
        state.form.cover = logo
      }

      const deleteLogo = () => {
        state.form.logo = ''
      }

      const deleteCover = () => {
        state.form.cover = ''
      }

      const deleteBannar = (url) => {
        console.log(url, 'bannar', state.form.banner.indexOf(url))
        let delIndex = state.form.banner.indexOf(url)
        if (state.form.banner.length == 1) {
          state.form.banner = ['']
        } else {
          state.form.banner.splice(delIndex, 1)
        }
      }

      const toolbarConfig = {
        excludeKeys: ['fullScreen', 'group-video'],
      }
      const editorConfig = {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            async customUpload(res, insertFn) {
              let upData = new FormData()
              upData.append('file', res)
              const { data } = await upLoadImg(upData)
              insertFn(data, res.name)
            },
          },
        },
      }

      onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
      })

      const handleClickFile = (item, index) => {
        let link = !!item ? [] : item.link
        state.chooseLinkRef.showDialog(link)
      }

      const confirmChoose = (val) => {
        state.link = val
      }

      const fetchData = async () => {
        console.log(route.query)
        state.form.advertisingCode = route.query.advertisingCode
        state.form.advertisingRelationCode = route.query.advertisingRelationCode

        state.queryParams.advertisingCode = route.query.advertisingCode
        const { data } = await getAdvertisingConfig(state.queryParams)
        console.log(data, '===>>>')
        // 数据回显
        if (data.list && data.list.length > 0) {
          let list = data.list[0]
          let params = JSON.parse(list.param)
          console.log(params)

          state.form.name = list.name
          state.form.id = list.id
          if (!params.extraParams.isVideo) {
            state.form.logo = params.img
            state.form.mediaType = 'img'
          } else {
            state.form.videoSrc = params.extraParams.videoSrc
            state.form.cover = params.img
            state.form.mediaType = 'video'
          }

          state.form.sort = params.index
          state.link = params.link
        }

        // const params = JSON.parse(state.)
        // if (state.status == 'edit') {
        //   state.exhibitorCode = route.query.id
        //   const { data } = await getBrandDetail({
        //     exhibitorCode: state.exhibitorCode,
        //   })
        //   data.banner = data.banner.split(',')
        //   console.log(data)
        //   state.form = Object.assign({}, data)
        // }
      }

      const upLoadVideo = () => {
        state.upLoadVideoRef.handleShow()
      }

      const handleAddVideo = (val) => {
        console.log(val, '=vvvvv')
        state.form.videoSrc = val
      }

      onMounted(() => {
        fetchData()
      })

      const goBack = () => {
        proxy.$router.go(-1)
      }

      return {
        ...toRefs(state),
        confirmChoose,
        handleAddVideo,
        upLoadVideo,
        upBannar,
        fetchData,
        submitInfo,
        handlePreview,
        upLoadLogo,
        logoInfo,
        coverInfo,
        upLoadCover,
        deleteLogo,
        deleteCover,
        deleteBannar,
        editorRef,
        mode: 'default',
        toolbarConfig,
        editorConfig,
        goBack,
        handleClickFile,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .upLoadImg {
    display: flex;
    flex-wrap: wrap;
    .draggable {
      display: flex;
      flex-wrap: wrap;
    }
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      background: #f5f7fa;
      color: #909399;
      font-size: 30px;
      margin-right: 20px;
    }
    .image-slot .el-icon {
      font-size: 30px;
      color: #909399;
    }
    .hoveBox {
      width: 200px;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
</style>
